var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "오더 자동생성 정보" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          !_vm.popupParam.pmMstId && _vm.editable
                            ? _c("c-btn", {
                                attrs: {
                                  label: "작업표준 불러오기",
                                  icon: "upload_file",
                                },
                                on: { btnClicked: _vm.standardData },
                              })
                            : _vm._e(),
                          Number(_vm.pmData.operationSumTime) >=
                            Number(_vm.pmData.operationTime) &&
                          Number(_vm.pmData.operationTime) != 0
                            ? _c("q-badge", {
                                staticClass: "blinking",
                                attrs: { color: "negative", label: "도래" },
                              })
                            : _vm._e(),
                          _vm.popupParam.pmMstId
                            ? _c("c-btn", {
                                attrs: {
                                  label: "삭제",
                                  icon: "delete_forever",
                                },
                                on: { btnClicked: _vm.removeData },
                              })
                            : _vm._e(),
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.saveUrl,
                                  isSubmit: _vm.isSave,
                                  param: _vm.pmData,
                                  mappingType: _vm.mappingType,
                                  label: "저장",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveData,
                                  btnCallback: _vm.saveCallback,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            required: true,
                            default: "today",
                            label: "최초 기준일",
                            name: "pmFirstDt",
                          },
                          on: { datachange: _vm.setNextDt },
                          model: {
                            value: _vm.pmData.pmFirstDt,
                            callback: function ($$v) {
                              _vm.$set(_vm.pmData, "pmFirstDt", $$v)
                            },
                            expression: "pmData.pmFirstDt",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            type: "number",
                            disabled: true,
                            label: "최근정비일",
                            name: "pmRecentDt",
                          },
                          on: { datachange: _vm.setNextDt },
                          model: {
                            value: _vm.pmData.pmRecentDt,
                            callback: function ($$v) {
                              _vm.$set(_vm.pmData, "pmRecentDt", $$v)
                            },
                            expression: "pmData.pmRecentDt",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.pmData.pmTypeCd === "TBM"
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                          },
                          [
                            _c("c-text", {
                              attrs: {
                                required: true,
                                editable: _vm.editable,
                                type: "number",
                                label: "PM 주기",
                                name: "pmCycleTerm",
                              },
                              on: { dataChange: _vm.setNextDt },
                              model: {
                                value: _vm.pmData.pmCycleTerm,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pmData, "pmCycleTerm", $$v)
                                },
                                expression: "pmData.pmCycleTerm",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.pmData.pmTypeCd === "TBM"
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                          },
                          [
                            _c("c-select", {
                              attrs: {
                                required: true,
                                editable: _vm.editable,
                                type: "edit",
                                codeGroupCd: "PM_CYCLE_UNIT_CD",
                                itemText: "codeName",
                                itemValue: "code",
                                name: "pmCycleUnitCd",
                                label: "PM주기 단위",
                              },
                              on: { datachange: _vm.setNextDt },
                              model: {
                                value: _vm.pmData.pmCycleUnitCd,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pmData, "pmCycleUnitCd", $$v)
                                },
                                expression: "pmData.pmCycleUnitCd",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.pmData.pmTypeCd === "UBM"
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                          },
                          [
                            _c("c-text", {
                              attrs: {
                                required: true,
                                type: "number",
                                label: "가동시간(PM생성 기준)",
                                suffix: "시간",
                                name: "operationTime",
                              },
                              on: { dataChange: _vm.setNextDt },
                              model: {
                                value: _vm.pmData.operationTime,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pmData, "operationTime", $$v)
                                },
                                expression: "pmData.operationTime",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.pmData.pmTypeCd === "UBM"
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                          },
                          [
                            _c("c-text", {
                              attrs: {
                                disabled: true,
                                type: "number",
                                label: "설비 총 가동시간(최초 기준일 기준)",
                                suffix: "시간",
                                name: "operationSumTime",
                              },
                              model: {
                                value: _vm.pmData.operationSumTime,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pmData, "operationSumTime", $$v)
                                },
                                expression: "pmData.operationSumTime",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.pmData.pmTypeCd === "TBM"
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                          },
                          [
                            _c("c-datepicker", {
                              attrs: {
                                disabled: true,
                                label: "차기정비일",
                                name: "pmNextDt",
                              },
                              model: {
                                value: _vm.pmData.pmNextDt,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pmData, "pmNextDt", $$v)
                                },
                                expression: "pmData.pmNextDt",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.pmData.pmTypeCd === "TBM"
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                          },
                          [
                            _c("c-text", {
                              attrs: {
                                required: true,
                                editable: _vm.editable,
                                suffix: "일",
                                type: "number",
                                label: "정비계획기간 수",
                                name: "planPeriod",
                              },
                              model: {
                                value: _vm.pmData.planPeriod,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pmData, "planPeriod", $$v)
                                },
                                expression: "pmData.planPeriod",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.pmData.pmTypeCd === "TBM"
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                          },
                          [
                            _c("c-text", {
                              attrs: {
                                type: "number",
                                label: "오더작성일",
                                prefix: "차기정비일 -",
                                suffix: "일",
                                name: "pmBaseDate",
                              },
                              on: { dataChange: _vm.setOrderDt },
                              model: {
                                value: _vm.pmData.pmBaseDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pmData, "pmBaseDate", $$v)
                                },
                                expression: "pmData.pmBaseDate",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.pmData.pmTypeCd === "TBM"
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                          },
                          [
                            _c("c-datepicker", {
                              attrs: {
                                disabled: true,
                                label: "차기오더발생예정일",
                                name: "pmOrderDate",
                              },
                              model: {
                                value: _vm.pmData.pmOrderDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pmData, "pmOrderDate", $$v)
                                },
                                expression: "pmData.pmOrderDate",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            isFlag: true,
                            falseLabel: "No",
                            label: "PM 자동 생성여부",
                            name: "produceFlag",
                          },
                          model: {
                            value: _vm.pmData.produceFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.pmData, "produceFlag", $$v)
                            },
                            expression: "pmData.produceFlag",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "예방정비 개요" },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            label: "PM명",
                            name: "pmMstName",
                          },
                          model: {
                            value: _vm.pmData.pmMstName,
                            callback: function ($$v) {
                              _vm.$set(_vm.pmData, "pmMstName", $$v)
                            },
                            expression: "pmData.pmMstName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-plant", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.pmData.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.pmData, "plantCd", $$v)
                            },
                            expression: "pmData.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-equip", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            label: "대표설비Tag",
                            name: "equipmentCd",
                          },
                          on: { dataChange: _vm.equipChange },
                          model: {
                            value: _vm.pmData.equipmentCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.pmData, "equipmentCd", $$v)
                            },
                            expression: "pmData.equipmentCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-func-location", {
                          attrs: {
                            type: "edit",
                            plantCd: _vm.pmData.plantCd,
                            editable: _vm.editable,
                            label: "기능위치",
                            name: "funcLocationCd",
                          },
                          model: {
                            value: _vm.pmData.funcLocationCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.pmData, "funcLocationCd", $$v)
                            },
                            expression: "pmData.funcLocationCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-dept", {
                          attrs: {
                            type: "edit",
                            required: true,
                            editable: _vm.editable,
                            label: "정비오더 발행부서",
                            name: "workDeptCd",
                          },
                          model: {
                            value: _vm.pmData.workDeptCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.pmData, "workDeptCd", $$v)
                            },
                            expression: "pmData.workDeptCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-field", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            data: _vm.pmData,
                            type: "user",
                            label: "작업계획자",
                            name: "planUserId",
                          },
                          model: {
                            value: _vm.pmData.planUserId,
                            callback: function ($$v) {
                              _vm.$set(_vm.pmData, "planUserId", $$v)
                            },
                            expression: "pmData.planUserId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-dept-multi", {
                          attrs: {
                            required: "",
                            isArray: false,
                            editable: _vm.editable,
                            label: "정비작업팀",
                            name: "workDepts",
                          },
                          model: {
                            value: _vm.pmData.workDepts,
                            callback: function ($$v) {
                              _vm.$set(_vm.pmData, "workDepts", $$v)
                            },
                            expression: "pmData.workDepts",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            isFlag: true,
                            falseLabel: "No",
                            label: "안전작업허가 발행여부",
                            name: "permitFlag",
                          },
                          model: {
                            value: _vm.pmData.permitFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.pmData, "permitFlag", $$v)
                            },
                            expression: "pmData.permitFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                    }),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            rows: 7,
                            label: "정비요청 세부사항",
                            name: "remark",
                          },
                          model: {
                            value: _vm.pmData.remark,
                            callback: function ($$v) {
                              _vm.$set(_vm.pmData, "remark", $$v)
                            },
                            expression: "pmData.remark",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c(
                          "c-table",
                          {
                            ref: "gridCost",
                            attrs: {
                              title: "비용정산 규칙",
                              columns: _vm.gridCost.columns,
                              data: _vm.pmData.costList,
                              gridHeight: _vm.gridCost.height,
                              editable: _vm.editable,
                              hideBottom: true,
                              isExcelDown: false,
                              filtering: false,
                              isFullScreen: false,
                              columnSetting: false,
                              selection: "multiple",
                              rowKey: "costCenterCd",
                            },
                          },
                          [
                            _c(
                              "template",
                              { slot: "table-button" },
                              [
                                _c(
                                  "q-btn-group",
                                  { attrs: { outline: "" } },
                                  [
                                    _vm.editable
                                      ? _c("c-btn", {
                                          attrs: { label: "추가", icon: "add" },
                                          on: { btnClicked: _vm.addRowCost },
                                        })
                                      : _vm._e(),
                                    _vm.editable
                                      ? _c("c-btn", {
                                          attrs: {
                                            label: "삭제",
                                            icon: "remove",
                                          },
                                          on: { btnClicked: _vm.removeRowCost },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c(
                          "c-table",
                          {
                            ref: "grid0",
                            attrs: {
                              title: "관련설비",
                              columns: _vm.grid0.columns,
                              data: _vm.grid0.data,
                              gridHeight: _vm.grid0.height,
                              editable: _vm.editable,
                              hideBottom: true,
                              isExcelDown: false,
                              filtering: false,
                              isFullScreen: false,
                              columnSetting: false,
                              selection: "multiple",
                              rowKey: "equipmentCd",
                            },
                          },
                          [
                            _c(
                              "template",
                              { slot: "table-button" },
                              [
                                _c(
                                  "q-btn-group",
                                  { attrs: { outline: "" } },
                                  [
                                    _vm.editable && _vm.isOld
                                      ? _c("c-btn", {
                                          attrs: { label: "추가", icon: "add" },
                                          on: { btnClicked: _vm.addRowEquip },
                                        })
                                      : _vm._e(),
                                    _vm.editable && _vm.isOld
                                      ? _c("c-btn", {
                                          attrs: {
                                            label: "삭제",
                                            icon: "remove",
                                          },
                                          on: {
                                            btnClicked: _vm.removeRowEquip,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-card",
                { attrs: { noHeader: true, noMarginPadding: true } },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                      },
                      [
                        _c("c-table", {
                          ref: "gridWork",
                          attrs: {
                            title: "작업계획 목록",
                            columns: _vm.gridWork.columns,
                            data: _vm.gridWork.data,
                            gridHeight: _vm.gridWork.height,
                            editable: _vm.editable,
                            isDense: true,
                            bgHeaderClass: "bg-grid-header-main",
                            hideBottom: true,
                            isExcelDown: false,
                            gridHeightAuto: true,
                            filtering: false,
                            isFullScreen: false,
                            columnSetting: false,
                          },
                          on: { rowClick: _vm.rowClick },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                      },
                      [
                        _c(
                          "q-form",
                          { ref: "editForm2" },
                          [
                            _c(
                              "c-card",
                              {
                                staticClass: "cardClassDetailForm",
                                attrs: { title: "작업계획 상세" },
                              },
                              [
                                _c(
                                  "template",
                                  { slot: "card-button" },
                                  [
                                    _c(
                                      "q-btn-group",
                                      { attrs: { outline: "" } },
                                      [
                                        _vm.editable && _vm.isOld
                                          ? _c("c-btn", {
                                              attrs: {
                                                label: "작업계획 추가",
                                                icon: "add",
                                              },
                                              on: {
                                                btnClicked: _vm.addRowWork,
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.editable &&
                                        _vm.isOld &&
                                        _vm.selectedWork &&
                                        _vm.workUpdateMode
                                          ? _c("c-btn", {
                                              attrs: {
                                                label: "작업계획 삭제",
                                                icon: "remove",
                                              },
                                              on: {
                                                btnClicked: _vm.removeRowWork,
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.editable &&
                                        _vm.isOld &&
                                        _vm.selectedWork
                                          ? _c("c-btn", {
                                              attrs: {
                                                url: _vm.saveWorkUrl,
                                                isSubmit: _vm.isSave2,
                                                param: _vm.workData,
                                                mappingType: _vm.mappingType2,
                                                label: "작업계획 저장",
                                                icon: "save",
                                              },
                                              on: {
                                                beforeAction: _vm.saveWork,
                                                btnCallback:
                                                  _vm.saveCallbackWork,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("template", { slot: "card-detail" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1",
                                      staticStyle: {
                                        "padding-top": "25px !important",
                                      },
                                    },
                                    [
                                      _c("c-text", {
                                        attrs: {
                                          required: true,
                                          editable:
                                            _vm.editable && _vm.selectedWork,
                                          label: "항목번호",
                                          name: "sortOrder",
                                          type: "number",
                                        },
                                        model: {
                                          value: _vm.workData.sortOrder,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.workData,
                                              "sortOrder",
                                              $$v
                                            )
                                          },
                                          expression: "workData.sortOrder",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5",
                                      staticStyle: {
                                        "padding-top": "25px !important",
                                      },
                                    },
                                    [
                                      _c("c-text", {
                                        attrs: {
                                          required: true,
                                          editable:
                                            _vm.editable && _vm.selectedWork,
                                          label: "작업명",
                                          name: "pmMstWorkName",
                                        },
                                        model: {
                                          value: _vm.workData.pmMstWorkName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.workData,
                                              "pmMstWorkName",
                                              $$v
                                            )
                                          },
                                          expression: "workData.pmMstWorkName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                                      staticStyle: {
                                        "padding-top": "25px !important",
                                      },
                                    },
                                    [
                                      _c("c-select", {
                                        attrs: {
                                          required: true,
                                          editable:
                                            _vm.editable && _vm.selectedWork,
                                          codeGroupCd: "WO_OPR_TYPE_CD",
                                          type: "edit",
                                          itemText: "codeName",
                                          itemValue: "code",
                                          name: "workOprTypeCd",
                                          label: "작업제어구분",
                                        },
                                        on: {
                                          datachange: _vm.changeWorkOprType,
                                        },
                                        model: {
                                          value: _vm.workData.workOprTypeCd,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.workData,
                                              "workOprTypeCd",
                                              $$v
                                            )
                                          },
                                          expression: "workData.workOprTypeCd",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.workData.workOprTypeCd !== "WOTC000001"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                                          staticStyle: {
                                            "padding-top": "25px !important",
                                          },
                                        },
                                        [
                                          _c("c-vendor", {
                                            attrs: {
                                              editable:
                                                _vm.editable &&
                                                _vm.selectedWork,
                                              label: "작업업체",
                                              name: "vendorCd",
                                            },
                                            model: {
                                              value: _vm.workData.vendorCd,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.workData,
                                                  "vendorCd",
                                                  $$v
                                                )
                                              },
                                              expression: "workData.vendorCd",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.workData.workOprTypeCd === "WOTC000001"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                                          staticStyle: {
                                            "padding-top": "25px !important",
                                          },
                                        },
                                        [
                                          _c("c-dept", {
                                            attrs: {
                                              type: "edit",
                                              isFirstValue: _vm.workUpdateMode,
                                              editable:
                                                _vm.editable &&
                                                _vm.selectedWork,
                                              label: "작업부서",
                                              name: "workDeptCd",
                                            },
                                            model: {
                                              value: _vm.workData.workDeptCd,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.workData,
                                                  "workDeptCd",
                                                  $$v
                                                )
                                              },
                                              expression: "workData.workDeptCd",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                                    },
                                    [
                                      _c("c-checkbox", {
                                        attrs: {
                                          isArray: false,
                                          editable:
                                            _vm.editable && _vm.selectedWork,
                                          codeGroupCd: "SOP_WORK_TYPE_CD",
                                          itemText: "codeName",
                                          itemValue: "code",
                                          label: "작업허가서 구분",
                                          name: "workpermitTypes",
                                        },
                                        model: {
                                          value: _vm.workData.workpermitTypes,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.workData,
                                              "workpermitTypes",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "workData.workpermitTypes",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                                    },
                                    [
                                      _c("c-checkbox", {
                                        attrs: {
                                          isArray: false,
                                          editable:
                                            _vm.editable && _vm.selectedWork,
                                          codeGroupCd: "WO_RISK_FACTOR",
                                          itemText: "codeName",
                                          itemValue: "code",
                                          label: "위험요인",
                                          name: "workRiskFactors",
                                        },
                                        model: {
                                          value: _vm.workData.workRiskFactors,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.workData,
                                              "workRiskFactors",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "workData.workRiskFactors",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                        staticStyle: { "padding-top": "15px !important" },
                      },
                      [
                        _c(
                          "c-table",
                          {
                            ref: "grid",
                            attrs: {
                              title: "작업계획별 소요자재",
                              columns: _vm.grid.columns,
                              data: _vm.workData.materialList,
                              gridHeight: _vm.grid.height,
                              editable: _vm.editable && _vm.selectedWork,
                              hideBottom: true,
                              isExcelDown: false,
                              filtering: false,
                              isFullScreen: false,
                              columnSetting: false,
                              selection: "multiple",
                              rowKey: "materialCd",
                            },
                            on: {
                              "table-data-change": _vm.tableDataChangeMaterial,
                            },
                          },
                          [
                            _c(
                              "template",
                              { slot: "table-button" },
                              [
                                _c(
                                  "q-btn-group",
                                  { attrs: { outline: "" } },
                                  [
                                    _vm.editable &&
                                    _vm.isOld &&
                                    _vm.selectedWork
                                      ? _c("c-btn", {
                                          attrs: {
                                            label: "설비BOM 추가",
                                            icon: "add",
                                          },
                                          on: { btnClicked: _vm.addRowBom },
                                        })
                                      : _vm._e(),
                                    _vm.editable &&
                                    _vm.isOld &&
                                    _vm.selectedWork
                                      ? _c("c-btn", {
                                          attrs: {
                                            label: "자재마스터 추가",
                                            icon: "add",
                                          },
                                          on: { btnClicked: _vm.addRow },
                                        })
                                      : _vm._e(),
                                    _vm.editable &&
                                    _vm.isOld &&
                                    _vm.selectedWork
                                      ? _c("c-btn", {
                                          attrs: {
                                            label: "제외",
                                            icon: "remove",
                                          },
                                          on: { btnClicked: _vm.removeRow },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                      },
                      [
                        _c(
                          "c-table",
                          {
                            ref: "grid2",
                            attrs: {
                              title: "작업계획별 서비스(외주)",
                              columns: _vm.grid2.columns,
                              data: _vm.workData.serviceList,
                              gridHeight: _vm.grid2.height,
                              editable: _vm.editable && _vm.selectedWork,
                              hideBottom: true,
                              isExcelDown: false,
                              filtering: false,
                              isFullScreen: false,
                              columnSetting: false,
                              selection: "multiple",
                              rowKey: "vendorCd",
                            },
                            on: {
                              "table-data-change": _vm.tableDataChangeService,
                            },
                          },
                          [
                            _c(
                              "template",
                              { slot: "table-button" },
                              [
                                _c(
                                  "q-btn-group",
                                  { attrs: { outline: "" } },
                                  [
                                    _vm.editable &&
                                    _vm.isOld &&
                                    _vm.selectedWork
                                      ? _c("c-btn", {
                                          attrs: { label: "추가", icon: "add" },
                                          on: { btnClicked: _vm.addRowService },
                                        })
                                      : _vm._e(),
                                    _vm.editable &&
                                    _vm.isOld &&
                                    _vm.selectedWork
                                      ? _c("c-btn", {
                                          attrs: {
                                            label: "제외",
                                            icon: "remove",
                                          },
                                          on: {
                                            btnClicked: _vm.removeRowService,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c(
                          "c-table",
                          {
                            ref: "toolGrid",
                            attrs: {
                              title: "공기구",
                              columns: _vm.toolGrid.columns,
                              data: _vm.workData.toolList,
                              gridHeight: _vm.toolGrid.height,
                              editable: _vm.editable && _vm.selectedWork,
                              hideBottom: true,
                              isExcelDown: false,
                              filtering: false,
                              isFullScreen: false,
                              columnSetting: false,
                              selection: "multiple",
                              rowKey: "toolId",
                            },
                          },
                          [
                            _c(
                              "template",
                              { slot: "table-button" },
                              [
                                _c(
                                  "q-btn-group",
                                  { attrs: { outline: "" } },
                                  [
                                    _vm.editable &&
                                    _vm.isOld &&
                                    _vm.selectedWork
                                      ? _c("c-btn", {
                                          attrs: { label: "추가", icon: "add" },
                                          on: { btnClicked: _vm.addRowTool },
                                        })
                                      : _vm._e(),
                                    _vm.editable &&
                                    _vm.isOld &&
                                    _vm.selectedWork
                                      ? _c("c-btn", {
                                          attrs: {
                                            label: "제외",
                                            icon: "remove",
                                          },
                                          on: { btnClicked: _vm.removeRowTool },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c(
                          "c-table",
                          {
                            ref: "workerGrid",
                            attrs: {
                              title: "작업자",
                              columns: _vm.workerGrid.columns,
                              data: _vm.workData.workerList,
                              gridHeight: _vm.workerGrid.height,
                              editable: _vm.editable && _vm.selectedWork,
                              hideBottom: true,
                              isExcelDown: false,
                              filtering: false,
                              isFullScreen: false,
                              columnSetting: false,
                              selection: "multiple",
                              rowKey: "pmMstWorkerId",
                            },
                          },
                          [
                            _c(
                              "template",
                              { slot: "table-button" },
                              [
                                _c(
                                  "q-btn-group",
                                  { attrs: { outline: "" } },
                                  [
                                    _vm.editable &&
                                    _vm.isOld &&
                                    _vm.selectedWork
                                      ? _c("c-btn", {
                                          attrs: { label: "선택", icon: "add" },
                                          on: { btnClicked: _vm.addRowWorker },
                                        })
                                      : _vm._e(),
                                    _vm.editable &&
                                    _vm.isOld &&
                                    _vm.selectedWork
                                      ? _c("c-btn", {
                                          attrs: {
                                            label: "직접추가",
                                            icon: "add",
                                          },
                                          on: { btnClicked: _vm.addRowWorker2 },
                                        })
                                      : _vm._e(),
                                    _vm.editable &&
                                    _vm.isOld &&
                                    _vm.selectedWork
                                      ? _c("c-btn", {
                                          attrs: {
                                            label: "제외",
                                            icon: "remove",
                                          },
                                          on: {
                                            btnClicked: _vm.removeRowWorker,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }