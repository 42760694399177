<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card class="cardClassDetailForm" title="오더 자동생성 정보">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="!popupParam.pmMstId && editable" label="작업표준 불러오기" icon="upload_file" @btnClicked="standardData" />
                <q-badge v-if="Number(pmData.operationSumTime) >= Number(pmData.operationTime) && Number(pmData.operationTime) != 0" color="negative" label="도래" class="blinking" />
                <c-btn v-if="popupParam.pmMstId " label="삭제" icon="delete_forever" @btnClicked="removeData" />
                <c-btn
                  v-if="editable "
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="pmData"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <!-- <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-select
                  :required="true"
                  :editable="editable"
                  codeGroupCd="PM_TYPE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="pmTypeCd"
                  label="PM유형"
                  v-model="pmData.pmTypeCd"
                ></c-select>
              </div> -->
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-datepicker
                  :required="true"
                  default="today"
                  label="최초 기준일"
                  name="pmFirstDt"
                  v-model="pmData.pmFirstDt"
                  @datachange="setNextDt"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-datepicker
                  type="number"
                  :disabled="true"
                  label="최근정비일"
                  name="pmRecentDt"
                  v-model="pmData.pmRecentDt"
                  @datachange="setNextDt"
                />
              </div>
              <div v-if="pmData.pmTypeCd === 'TBM'" class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :required="true"
                  :editable="editable"
                  type="number"
                  label="PM 주기"
                  name="pmCycleTerm"
                  v-model="pmData.pmCycleTerm"
                  @dataChange="setNextDt">
                </c-text>
              </div>
              <div v-if="pmData.pmTypeCd === 'TBM'" class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-select
                  :required="true"
                  :editable="editable"
                  type="edit"
                  codeGroupCd="PM_CYCLE_UNIT_CD"
                  itemText="codeName"
                  itemValue="code"
                  name="pmCycleUnitCd"
                  label="PM주기 단위"
                  v-model="pmData.pmCycleUnitCd"
                  @datachange="setNextDt"
                ></c-select>
              </div>
              <div v-if="pmData.pmTypeCd === 'UBM'" class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :required="true"
                  type="number"
                  label="가동시간(PM생성 기준)"
                  suffix="시간"
                  name="operationTime"
                  v-model="pmData.operationTime"
                  @dataChange="setNextDt"
                />
              </div>
              <div v-if="pmData.pmTypeCd === 'UBM'" class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :disabled="true"
                  type="number"
                  label="설비 총 가동시간(최초 기준일 기준)"
                  suffix="시간"
                  name="operationSumTime"
                  v-model="pmData.operationSumTime"
                />
              </div>
              <div v-if="pmData.pmTypeCd === 'TBM'" class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-datepicker
                  :disabled="true"
                  label="차기정비일"
                  name="pmNextDt"
                  v-model="pmData.pmNextDt"
                />
              </div>
              <div v-if="pmData.pmTypeCd === 'TBM'" class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :required="true"
                  :editable="editable"
                  suffix="일"
                  type="number"
                  label="정비계획기간 수"
                  name="planPeriod"
                  v-model="pmData.planPeriod">
                </c-text>
              </div>
              <div v-if="pmData.pmTypeCd === 'TBM'" class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  type="number"
                  label="오더작성일"
                  prefix="차기정비일 -"
                  suffix="일"
                  name="pmBaseDate"
                  v-model="pmData.pmBaseDate"
                  @dataChange="setOrderDt"
                />
              </div>
              <div v-if="pmData.pmTypeCd === 'TBM'" class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-datepicker
                  :disabled="true"
                  label="차기오더발생예정일"
                  name="pmOrderDate"
                  v-model="pmData.pmOrderDate"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-checkbox
                  :isFlag="true"
                  falseLabel="No"
                  label="PM 자동 생성여부"
                  name="produceFlag"
                  v-model="pmData.produceFlag"
                />
              </div>
              <!-- 
              <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="false"
                  label="최초작성자"
                  name="regUserName"
                  type="regUserName"
                  v-model="pmData.regUserName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="false"
                  label="최초작성일시"
                  name="regDtStrTime"
                  type="regDtStrTime"
                  v-model="pmData.regDtStrTime">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="false"
                  label="최근수정자"
                  name="chgUserName"
                  type="chgUserName"
                  v-model="pmData.chgUserName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="false"
                  label="최근수정일시"
                  name="chgDtStrTime"
                  type="chgDtStrTime"
                  v-model="pmData.chgDtStrTime">
                </c-text>
              </div> -->
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card class="cardClassDetailForm" title="예방정비 개요">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="PM명"
                  name="pmMstName"
                  v-model="pmData.pmMstName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-plant
                  :required="true"
                  :editable="editable"
                  name="plantCd"
                  v-model="pmData.plantCd">
                </c-plant>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-equip
                  :required="true"
                  :editable="editable"
                  label="대표설비Tag"
                  name="equipmentCd"
                  v-model="pmData.equipmentCd"
                  @dataChange="equipChange">
                </c-equip>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-func-location 
                  type="edit" 
                  :plantCd="pmData.plantCd" 
                  :editable="editable" 
                  label="기능위치" 
                  name="funcLocationCd" 
                  v-model="pmData.funcLocationCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-dept type="edit" :required="true" :editable="editable" label="정비오더 발행부서" name="workDeptCd" v-model="pmData.workDeptCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-field
                  :required="true"
                  :editable="editable"
                  :data="pmData"
                  type="user"
                  label="작업계획자"
                  name="planUserId"
                  v-model="pmData.planUserId" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-dept-multi
                  required
                  :isArray="false"
                  :editable="editable"
                  label="정비작업팀" 
                  name="workDepts" 
                  v-model="pmData.workDepts"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-checkbox
                  :isFlag="true"
                  falseLabel="No"
                  label="안전작업허가 발행여부"
                  name="permitFlag"
                  v-model="pmData.permitFlag"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-textarea
                  :editable="editable"
                  :rows="7"
                  label="정비요청 세부사항"
                  name="remark"
                  v-model="pmData.remark">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-table
                  ref="gridCost"
                  title="비용정산 규칙"
                  :columns="gridCost.columns"
                  :data="pmData.costList"
                  :gridHeight="gridCost.height"
                  :editable="editable"
                  :hideBottom="true"
                  :isExcelDown="false"
                  :filtering="false"
                  :isFullScreen="false"
                  :columnSetting="false"
                  selection="multiple"
                  rowKey="costCenterCd"
                >
                  <template slot="table-button">
                    <q-btn-group outline >
                      <c-btn label="추가" icon="add" @btnClicked="addRowCost" v-if="editable" />
                      <c-btn label="삭제" icon="remove" @btnClicked="removeRowCost" v-if="editable" />
                    </q-btn-group>
                  </template>
                </c-table>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-table
                  ref="grid0"
                  title="관련설비"
                  :columns="grid0.columns"
                  :data="grid0.data"
                  :gridHeight="grid0.height"
                  :editable="editable"
                  :hideBottom="true"
                  :isExcelDown="false"
                  :filtering="false"
                  :isFullScreen="false"
                  :columnSetting="false"
                  selection="multiple"
                  rowKey="equipmentCd"
                >
                  <template slot="table-button">
                    <q-btn-group outline >
                      <c-btn label="추가" icon="add" @btnClicked="addRowEquip" v-if="editable && isOld" />
                      <c-btn label="삭제" icon="remove" @btnClicked="removeRowEquip" v-if="editable && isOld" />
                    </q-btn-group>
                  </template>
                </c-table>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card :noHeader="true" :noMarginPadding="true">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-table
                  ref="gridWork"
                  title="작업계획 목록"
                  :columns="gridWork.columns"
                  :data="gridWork.data"
                  :gridHeight="gridWork.height"
                  :editable="editable"
                  :isDense="true"
                  bgHeaderClass="bg-grid-header-main"
                  :hideBottom="true"
                  :isExcelDown="false"
                  :gridHeightAuto="true"
                  :filtering="false"
                  :isFullScreen="false"
                  :columnSetting="false"
                  @rowClick="rowClick"
                >
                </c-table>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <q-form ref="editForm2">
                  <c-card class="cardClassDetailForm" title="작업계획 상세">
                    <template slot="card-button">
                      <q-btn-group outline >
                        <c-btn label="작업계획 추가" icon="add" @btnClicked="addRowWork" v-if="editable && isOld" />
                        <c-btn label="작업계획 삭제" icon="remove" @btnClicked="removeRowWork" v-if="editable && isOld&&selectedWork&&workUpdateMode" />
                        <c-btn
                          v-if="editable && isOld&&selectedWork"
                          :url="saveWorkUrl"
                          :isSubmit="isSave2"
                          :param="workData"
                          :mappingType="mappingType2"
                          label="작업계획 저장"
                          icon="save"
                          @beforeAction="saveWork"
                          @btnCallback="saveCallbackWork" />
                      </q-btn-group>
                    </template>
                    <template slot="card-detail">
                      <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1" style="padding-top:25px !important;">
                        <c-text
                          :required="true"
                          :editable="editable&&selectedWork"
                          label="항목번호"
                          name="sortOrder"
                          type="number"
                          v-model="workData.sortOrder">
                        </c-text>
                      </div>
                      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" style="padding-top:25px !important;">
                        <c-text
                          :required="true"
                          :editable="editable&&selectedWork"
                          label="작업명"
                          name="pmMstWorkName"
                          v-model="workData.pmMstWorkName">
                        </c-text>
                      </div>
                      <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" style="padding-top:25px !important;">
                        <c-select
                          :required="true"
                          :editable="editable&&selectedWork"
                          codeGroupCd="WO_OPR_TYPE_CD"
                          type="edit"
                          itemText="codeName"
                          itemValue="code"
                          name="workOprTypeCd"
                          label="작업제어구분"
                          v-model="workData.workOprTypeCd"
                          @datachange="changeWorkOprType"
                        ></c-select>
                      </div>
                      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" v-if="workData.workOprTypeCd !== 'WOTC000001'" style="padding-top:25px !important;">
                        <c-vendor
                          :editable="editable&&selectedWork"
                          label="작업업체"
                          name="vendorCd"
                          v-model="workData.vendorCd" />
                      </div>
                      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" v-if="workData.workOprTypeCd === 'WOTC000001'" style="padding-top:25px !important;">
                        <c-dept type="edit" :isFirstValue="workUpdateMode" :editable="editable&&selectedWork" label="작업부서" name="workDeptCd" v-model="workData.workDeptCd" />
                      </div>
                      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <c-checkbox
                          :isArray="false"
                          :editable="editable&&selectedWork"
                          codeGroupCd="SOP_WORK_TYPE_CD"
                          itemText="codeName"
                          itemValue="code"
                          label="작업허가서 구분"
                          name="workpermitTypes"
                          v-model="workData.workpermitTypes"
                        />
                      </div>
                      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <c-checkbox
                          :isArray="false"
                          :editable="editable&&selectedWork"
                          codeGroupCd="WO_RISK_FACTOR"
                          itemText="codeName"
                          itemValue="code"
                          label="위험요인"
                          name="workRiskFactors"
                          v-model="workData.workRiskFactors"
                        />
                      </div>
                      <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" v-if="workData.workOprTypeCd !== 'WOTC000001'">
                        <c-text
                          :editable="editable&&selectedWork"
                          label="작업인원"
                          name="vendorWorkerCnt"
                          type="number"
                          suffix="명"
                          v-model="workData.vendorWorkerCnt">
                        </c-text>
                      </div> -->
                      <!-- <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9" v-if="workData.workOprTypeCd === 'WOTC000001'">
                        <c-multi-field 
                          :editable="editable&&selectedWork"
                          :userInfo.sync="userInfo"
                          isArray
                          type="dept_user"
                          label="내부작업자" 
                          name="workUsers" 
                          v-model="workData.workUsers" />
                      </div> -->
                    </template>
                  </c-card>
                </q-form>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-top:15px !important;">
                <c-table
                  ref="grid"
                  title="작업계획별 소요자재"
                  :columns="grid.columns"
                  :data="workData.materialList"
                  :gridHeight="grid.height"
                  :editable="editable&&selectedWork"
                  :hideBottom="true"
                  :isExcelDown="false"
                  :filtering="false"
                  :isFullScreen="false"
                  :columnSetting="false"
                  selection="multiple"
                  rowKey="materialCd"
                  @table-data-change="tableDataChangeMaterial"
                >
                  <template slot="table-button">
                    <q-btn-group outline >
                      <c-btn label="설비BOM 추가" icon="add" @btnClicked="addRowBom" v-if="editable && isOld&&selectedWork" />
                      <c-btn label="자재마스터 추가" icon="add" @btnClicked="addRow" v-if="editable && isOld&&selectedWork"/>
                      <c-btn label="제외" icon="remove" @btnClicked="removeRow" v-if="editable && isOld&&selectedWork"/>
                    </q-btn-group>
                  </template>
                </c-table>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-table
                  ref="grid2"
                  title="작업계획별 서비스(외주)"
                  :columns="grid2.columns"
                  :data="workData.serviceList"
                  :gridHeight="grid2.height"
                  :editable="editable&&selectedWork"
                  :hideBottom="true"
                  :isExcelDown="false"
                  :filtering="false"
                  :isFullScreen="false"
                  :columnSetting="false"
                  selection="multiple"
                  rowKey="vendorCd"
                  @table-data-change="tableDataChangeService"
                >
                  <template slot="table-button">
                    <q-btn-group outline >
                      <c-btn label="추가" icon="add" @btnClicked="addRowService" v-if="editable && isOld&&selectedWork"/>
                      <c-btn label="제외" icon="remove" @btnClicked="removeRowService" v-if="editable && isOld&&selectedWork"/>
                    </q-btn-group>
                  </template>
                </c-table>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <c-table
                  ref="toolGrid"
                  title="공기구"
                  :columns="toolGrid.columns"
                  :data="workData.toolList"
                  :gridHeight="toolGrid.height"
                  :editable="editable&&selectedWork"
                  :hideBottom="true"
                  :isExcelDown="false"
                  :filtering="false"
                  :isFullScreen="false"
                  :columnSetting="false"
                  selection="multiple"
                  rowKey="toolId"
                >
                  <template slot="table-button">
                    <q-btn-group outline >
                      <c-btn label="추가" icon="add" @btnClicked="addRowTool" v-if="editable && isOld&&selectedWork"/>
                      <c-btn label="제외" icon="remove" @btnClicked="removeRowTool" v-if="editable && isOld&&selectedWork"/>
                    </q-btn-group>
                  </template>
                </c-table>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <c-table
                  ref="workerGrid"
                  title="작업자"
                  :columns="workerGrid.columns"
                  :data="workData.workerList"
                  :gridHeight="workerGrid.height"
                  :editable="editable&&selectedWork"
                  :hideBottom="true"
                  :isExcelDown="false"
                  :filtering="false"
                  :isFullScreen="false"
                  :columnSetting="false"
                  selection="multiple"
                  rowKey="pmMstWorkerId"
                >
                  <template slot="table-button">
                    <q-btn-group outline >
                      <c-btn label="선택" icon="add" @btnClicked="addRowWorker" v-if="editable && isOld&&selectedWork"/>
                      <c-btn label="직접추가" icon="add" @btnClicked="addRowWorker2" v-if="editable && isOld&&selectedWork"/>
                      <c-btn label="제외" icon="remove" @btnClicked="removeRowWorker" v-if="editable && isOld&&selectedWork"/>
                    </q-btn-group>
                  </template>
                </c-table>
              </div>
            </template>
          </c-card>
        </div>
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'preventive-maintenance-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        pmMstId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      deleteUrl1: '',
      popupOptions: {
        isFull: false,
        width: '50%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      editable: true,
      saveUrl: transactionConfig.pm.mst.insert.url,
      mappingType: 'POST',
      mappingType2: 'PUT',
      detailUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      equiplistUrl: '',
      insertequipUrl: '',
      removeequipUrl: '',
      costlistUrl: '',
      insertcostUrl: '',
      removecostUrl: '',
      worklistUrl: '',
      workgetUrl: '',
      saveWorkUrl: transactionConfig.pm.mst.work.insert.url,
      removeworkUrl: '',
      isSave: false,
      isSave2: false,
      isdelete: false,
      workUpdateMode: false,
      selectedWork: false,
      pmData: {
        plantCd: '',
        pmMstId: '',
        pmMstName: '',
        equipmentCd: '',
        funcLocationCd: '',
        costCenterCd: '',
        preWorkPlanId: '',
        workDeptCd: '',
        planUserId: '',
        pmTypeCd: 'TBM',
        workDepts: '',
        pmCycleTerm: '',
        pmCycleUnitCd: null,
        pmFirstDt: '',
        planPeriod: '',
        pmBaseDate: '',
        permitFlag: 'N',
        produceFlag: 'Y',
        pmNextDt: '',
        pmOrderDate: '',
        operationTime: '',
        remark: '',
        costList: [],
        equipList: [],
      },
      
      workData: {
        pmMstId: '',  // 정비오더 일련번호
        pmMstWorkId: '',  // 작업계획 일련번호
        pmMstWorkName: '',  // 작업내용
        sortOrder: '',  // 순번
        workOprTypeCd: null,  // 작업제어구분
        workStartDt: '',  // 작업 시작일
        workEndDt: '',  // 작업 종료일
        workpermitTypes: '',  // 작업허가서 종류
        sopWorkPermitId: '',  // 작업허가서 일련번호
        workRiskFactors: '',  // 위험요인
        workDeptCd: '',  // 작업부서
        vendorCd: '',  // 작업업체
        vendorWorkerCnt: '',  // 작업업체 작업인원
        workHolidayFlag: 'N',
        operationSumTime: '',
        materialList: [],
        deleteMaterialList: [],
        serviceList: [],
        deleteServiceList: [],
        toolList: [],
        deleteToolList: [],
        workerList: [],
        deleteWorkerList: [],
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
      },
      tempData: {
        sortOrder: '',
        workOprTypeCd: null,
        workDts: [],
        contents: '',
        workpermitCds: [],
        workRiskFactors: [],
        vendorCd: '',
        workUsers: [],
        workerCnt: '',
        permitNo: '',
      },
      grid0: {
        columns: [
          {
            name: 'equipmentNo',
            field: 'equipmentNo',
            label: '설비Tag',
            align: 'center',
            style: 'width:130px',
            sortable: false,
          },
          {
            name: 'equipmentNum',
            field: 'equipmentNum',
            label: '설비관리번호',
            align: 'center',
            style: 'width:130px',
            sortable: false,
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '설비명',
            align: 'center',
            sortable: false,
          },
          // {
          //   name: 'funcLocationName',
          //   field: 'funcLocationName',
          //   label: '기능위치',
          //   align: 'center',
          //   sortable: false,
          // },
        ],
        data: [],
        height: '170px',
      },
       gridWork: {
        columns: [
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '항목번호',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'pmMstWorkName',
            field: 'pmMstWorkName',
            label: '작업명',
            align: 'left',
            sortable: false,
          },
          {
            name: 'workOprTypeName',
            field: 'workOprTypeName',
            label: '작업제어구분',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'workDeptName',
            field: 'workDeptName',
            label: '작업부서',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'vendorName',
            field: 'vendorName',
            label: '작업업체',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'workpermitTypeNames',
            field: 'workpermitTypeNames',
            label: '작업허가서구분',
            align: 'left',
            style: 'width:180px',
            sortable: false,
          },
          {
            name: 'materialNames',
            field: 'materialNames',
            label: '소요자재',
            align: 'left',
            style: 'width:300px',
            sortable: false,
          },
          {
            name: 'vendorNames',
            field: 'vendorNames',
            label: '서비스 (외주)',
            align: 'left',
            style: 'width:300px',
            sortable: false,
          },
        ],
        data: [],
        height: '336px',
      },
      gridCost: {
        columns: [
          {
            name: 'costCenterNo',
            field: 'costCenterNo',
            label: '코스트센터 번호',
            align: 'center',
            style: 'width:110px',
            sortable: false,
          },
          {
            name: 'costCenterName',
            field: 'costCenterName',
            label: '코스트센터 명',
            align: 'left',
            sortable: false,
          },
          {
            name: 'calculatePercent',
            field: 'calculatePercent',
            label: '정산율(%)',
            align: 'center',
            type: 'number',
            style: 'width:90px',
            sortable: false,
          },
        ],
        data: [],
        height: '170px',
      },
      grid: {
        columns: [
          {
            name: 'materialNo',
            field: 'materialNo',
            label: '자재번호',
            align: 'center',
            style: 'width: 120px',
            sortable: false,
          },
          {
            name: 'materialName',
            field: 'materialName',
            label: '자재',
            align: 'center',
            style: 'width: 300px',
            sortable: false,
          },
          {
            name: 'materialDesc',
            field: 'materialDesc',
            label: '용도',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'unitPrice',
            field: 'unitPrice',
            label: '단가',
            type: 'number',
            style: 'width: 150px',
            sortable: false,
          },
          {
            name: 'unitEa',
            field: 'unitEa',
            label: '수량',
            type: 'number',
            required: true,
            style: 'width: 150px',
            sortable: false,
          },
          {
            name: 'priceCost',
            field: 'priceCost',
            label: '소계',
            type: 'cost',
            style: 'width: 150px',
            sortable: false,
          },
        ],
        data: [],
        height: '200px',
      },
      grid2: {
        columns: [],
        data: [],
        height: '200px',
      },
      toolGrid: {
        columns: [
          {
            name: 'toolName',
            field: 'toolName',
            label: '공기구명',
            style: 'width:200px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'toolStandard',
            field: 'toolStandard',
            label: '규격',
            style: 'width:200px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'toolCnt',
            field: 'toolCnt',
            label: '수량(개)',
            type: 'number',
            align: 'right',
            style: 'width:80px',
            sortable: false,
          },
        ],
        data: [],
        height: '220px',
      },
      workerGrid: {
        columns: [
          {
            name: 'workerName',
            field: 'workerName',
            label: '작업자',
            style: 'width:150px',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'skillName',
            field: 'skillName',
            label: '기술수준',
            style: 'width:300px',
            type: 'text',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
        height: '220px',
      },
      equipList: [],
      copyUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    userInfo() {
      return {
        userItems: this.tempData.workUsers,
        userText: 'workUserName',
        userValue: 'workUserId',
        deptText: 'workDeptName',
        deptValue: 'workDeptCd',
      }
    },
    isOld() {
      return Boolean(this.popupParam.pmMstId)
    }
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.detailUrl = selectConfig.pm.mst.get.url;
      this.insertUrl = transactionConfig.pm.mst.insert.url;
      this.updateUrl = transactionConfig.pm.mst.update.url;
      this.deleteUrl = transactionConfig.pm.mst.delete.url;
      this.copyUrl = transactionConfig.pm.mst.copy.url;

      this.equiplistUrl = selectConfig.pm.mst.equip.list.url;
      this.insertequipUrl = transactionConfig.pm.mst.equip.save.url;
      this.removeequipUrl = transactionConfig.pm.mst.equip.delete.url;
      this.costlistUrl = selectConfig.pm.mst.cost.list.url;
      this.insertcostUrl = transactionConfig.pm.mst.cost.save.url;
      this.removecostUrl = transactionConfig.pm.mst.cost.delete.url;
      
      this.worklistUrl = selectConfig.pm.mst.work.list.url;
      this.workgetUrl = selectConfig.pm.mst.work.get.url;
      this.saveworkUrl = transactionConfig.pm.mst.work.insert.url;
      this.removeworkUrl = transactionConfig.pm.mst.work.delete.url;

      this.operateUrl = selectConfig.mdm.equipment.operation.list.url;

      this.getData();
      this.getColumn();
    },
    getData() {
      if (this.popupParam.pmMstId) {
        this.getDetail(this.popupParam.pmMstId, true);
      } else {
        this.pmData.planUserId = this.$store.getters.user.userId;
      }
    },
    getOperateData() {
      this.$http.url = this.operateUrl + '/revs';
      this.$http.type = 'GET';
      this.$http.param = {
        groupId: this.pmData.equipmentCd
      };
      this.$http.request((_result) => {
        this.equipList = _result.data
      })
    },
    getDetail(_pmMstId) {
      this.$http.url = this.detailUrl;
      this.$http.param = {pmMstId: _pmMstId}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.pmData = _result.data;
        if (this.pmData.costCenterCd && this.pmData.costList.length == 0) {
          this.pmData.costList.push({
            pmMstId: this.popupParam.pmMstId,  // 작업계획 일련번호
            costCenterCd: this.pmData.costCenterCd,  // 코스트센터 코드
            costCenterNo: this.pmData.costCenterNo,  // 코스트센터 코드
            costCenterName: this.pmData.costCenterName,  // 코스트센터 코드
            calculatePercent: 100,  // 정산율
            calculateCost: '',  // 정산금액
            remark: '',  // 비고
            editFlag: 'C',
          })
        }
        if (!this.pmData.planUserId) {
          this.pmData.planUserId = this.$store.getters.user.userId;
        }
        this.getOperateData();
        this.getEquipList(_pmMstId);
        this.getWorks(_pmMstId);
      },);
    },
    getColumn() {
      this.$comm.getComboItems('WO_COST_FACOTR_CD').then(_result => {
        this.grid2.columns = [
        {
            name: 'vendorName',
            field: 'vendorName',
            label: '작업업체',
            align: 'center',
            style: 'width: 200px',
            sortable: false,
          },
          {
            required: true,
            name: 'workDt',
            field: 'workDt',
            label: '작업일',
            align: 'center',
            type: 'date',
            style: 'width: 140px',
            sortable: false,
          },
          {
            name: 'costFactorCd',
            field: 'costFactorCd',
            label: '원가요소',
            align: 'center',
            sortable: false,
            style: 'width: 200px',
            type: 'select',
            itemText: 'codeName',
            itemValue: 'code',
            comboItems: _result,
          },
          {
            name: 'manCnt',
            field: 'manCnt',
            label: '작업인원',
            type: 'number',
            style: 'width: 80px',
            sortable: false,
          },
          {
            name: 'priceCost',
            field: 'priceCost',
            label: '인력단가',
            type: 'number',
            style: 'width: 120px',
            sortable: false,
          },
          {
            name: 'manCost',
            field: 'manCost',
            label: '인건비',
            type: 'number',
            style: 'width: 120px',
            sortable: false,
          },
          {
            name: 'materialCost',
            field: 'materialCost',
            label: '자재비',
            type: 'number',
            style: 'width: 120px',
            sortable: false,
          },
          {
            name: 'sumCost',
            field: 'sumCost',
            label: '합계',
            type: 'cost',
            style: 'width: 120px',
            sortable: false,
          },
        ]
      });
    },
    saveData() {
      if (this.popupParam.pmMstId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.pmData.regUserId = this.$store.getters.user.userId;
              this.pmData.chgUserId = this.$store.getters.user.userId;
              this.pmData.woWorkTypeCd = 'WWTC000001'

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.pmMstId = result.data
      this.getData();
    },
    removeData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: 'PM마스터를 삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.pmMstId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isdelete = !this.isdelete;
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    tableDataChange(props, col) {
      if (col.name === 'workOprTypeCd') {
        this.$set(props.row, 'vendorWorkerCnt', 0);
        this.$set(props.row, 'workDeptCd', null);
        this.$set(props.row, 'vendorCd', null);
        this.$set(props.row, 'workUsers', null);
      }
    },
    
    getEquipList(_pmMstId) {
      if (this.popupParam.pmMstId || _pmMstId) {
        this.$http.url = this.equiplistUrl;
        this.$http.param = {pmMstId: _pmMstId || this.popupParam.pmMstId};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid0.data = _result.data;
        },);
      }
    },
    getWorks(_pmMstId) {
      if (this.popupParam.pmMstId || _pmMstId) {
        this.$http.url = this.worklistUrl;
        this.$http.param = {pmMstId: _pmMstId || this.popupParam.pmMstId};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.selectedWork = false;
          this.workUpdateMode = false;
          this.gridWork.data = _result.data;
        },);
      }
    },
    addRowEquip() {
      this.popupOptions.title = '설비 추가'; // 작업자 추가
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.pmData.plantCd
      };
      this.popupOptions.target = () => import(`${'@/pages/common/equip/equipPop.vue'}`);
      this.popupOptions.isFull = false;
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeaddRowEquip;
    },
    closeaddRowEquip(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      let _saveData = [];
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.grid0.data, { equipmentCd: item.equipmentCd }) === -1) {
            _saveData.push({
              pmMstId: this.popupParam.pmMstId,  // 작업지시번호
              equipmentNo: item.equipmentNo,  // 작업자 사번
              equipmentNum: item.equipmentNum,
              equipmentCd: item.equipmentCd,
              equipmentName: item.equipmentName,
              funcLocationName: item.funcLocationName,
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              mainEquipFlag: 'N',
              editFlag: 'C',
            })
          }
        });
        this.$http.url = this.insertequipUrl;
        this.$http.type = 'POST';
        this.$http.param = _saveData;
        this.$http.request(() => {
          this.getEquipList();
          window.getApp.$emit('APP_REQUEST_SUCCESS');
        });
      }
    },
    removeRowEquip() {
      let selectData = this.$refs['grid0'].selected;
      
      let filterData = this.$_.filter(selectData, item => {
        return item.mainEquipFlag === 'Y'
      })
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else if (filterData && filterData.length > 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '대표설비는 삭제할 수 없습니다.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.removeequipUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid0.data = this.$_.reject(this.grid0.data, item);
              })
              this.$refs['grid0'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    equipChange(data) {
      this.getOperateData();
      // 기능위치 변경
      if (data.funcLocationCd !== null) {
        if (this.pmData.funcLocationCd === null) {
          this.pmData.funcLocationCd = data.funcLocationCd
        } else {
          if (this.pmData.funcLocationCd != data.funcLocationCd) {
            this.pmData.funcLocationCd = data.funcLocationCd
          }
        }
      }
      if (data) {
        if (this.$_.findIndex(this.grid0.data, { equipmentCd: data.equipmentCd }) > -1) {
          this.$http.url = this.removeequipUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values([{
              pmMstId: this.popupParam.pmMstId,  // 작업지시번호
              equipmentNo: data.equipmentNo,
              equipmentCd: data.equipmentCd,
              equipmentName: data.equipmentName,
              funcLocationName: data.funcLocationName,
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
            }])
          };
          this.$http.request(() => {
            this.$refs['grid0'].$refs['compo-table'].clearSelection();
            this.saveMainEquip(data)
          },);
        } else {
          this.saveMainEquip(data);
        }
        if(this.pmData.costList.length == 0) {
          this.pmData.costList.push({
            pmMstId: this.popupParam.pmMstId,  // 작업계획 일련번호
            costCenterCd: data.costCenterCd,  // 코스트센터 코드
            costCenterNo: data.costCenterNo,  // 코스트센터 코드
            costCenterName: data.costCenterName,  // 코스트센터 코드
            calculatePercent: 100,  // 정산율
            calculateCost: '',  // 정산금액
            remark: '',  // 비고
            editFlag: 'C',
          })
        }
      }
    },
    saveMainEquip(data) {
      if (this.popupParam.pmMstId) {
        this.$http.url = this.insertequipUrl;
        this.$http.type = 'POST';
        this.$http.param = [{
          pmMstId: this.popupParam.pmMstId,  // 작업지시번호
          equipmentNo: data.equipmentNo,
          equipmentCd: data.equipmentCd,
          equipmentName: data.equipmentName,
          funcLocationName: data.funcLocationName,
          regUserId: this.$store.getters.user.userId,  // 등록자 ID
          mainEquipFlag: 'Y',
          editFlag: 'C',
        }];
        this.$http.request(() => {
          this.getEquipList();
        });
      } else {
        this.grid0.data.push(
          {
            pmMstId: this.popupParam.pmMstId,  // 작업지시번호
            equipmentNo: data.equipmentNo,
            equipmentCd: data.equipmentCd,
            equipmentName: data.equipmentName,
            funcLocationName: data.funcLocationName,
            regUserId: this.$store.getters.user.userId,  // 등록자 ID
            mainEquipFlag: 'Y',
            editFlag: 'C',
          }
        );
        this.pmData.equipList.push(
          {
            pmMstId: this.popupParam.pmMstId,  // 작업지시번호
            equipmentNo: data.equipmentNo,
            equipmentCd: data.equipmentCd,
            equipmentName: data.equipmentName,
            funcLocationName: data.funcLocationName,
            regUserId: this.$store.getters.user.userId,  // 등록자 ID
            mainEquipFlag: 'Y',
            editFlag: 'C',
          }
        )
      }
    },
    
    addRow() {
      this.popupOptions.title = '자재마스터 추가'; // 작업자재 추가
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/material/materialPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeaddRow;
    },
    closeaddRow(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.workData.materialList, { materialCd: item.materialCd }) === -1) {
            this.workData.materialList.push({
              pmMstId: this.popupParam.pmMstId,  // 작업지시번호
              pmMstWorkId: this.workData.pmMstWorkId,
              materialCd: item.materialCd,  // 작업자재 일련번호
              materialNo: item.materialNo,  // 작업자재 일련번호
              materialName: item.materialName,  // 작업자재
              materialDesc: '',  // 작업자재 용도
              unitPrice: item.price,  // 단가
              unitEa: '',  // 수량
              priceCost: '',  // 소계
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              editFlag: 'C',
            })
          }
        });
      }
    },
    removeRow() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.workData.deleteMaterialList) this.workData.deleteMaterialList = [];
        this.$_.forEach(selectData, item => {
          if (item.editFlag !== 'C'
            && this.$_.findIndex(this.workData.deleteMaterialList, { materialCd: item.materialCd }) === -1) {
            this.workData.deleteMaterialList.push(item)
          }
          this.workData.materialList = this.$_.reject(this.workData.materialList, item);
        })
        this.$refs['grid'].$refs['compo-table'].clearSelection();
      }
    },
    rowClick(row) {
      this.selectedWork = true;
      this.workUpdateMode = true;
      // 상세조회
      this.$http.url = this.workgetUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        pmMstId : this.popupParam.pmMstId,
        pmMstWorkId : row.pmMstWorkId,
      }
      this.$http.request((_result) => {
        this.workData = _result.data;
      },
      () => {
      });
    },
    addRowWork() {
      this.selectedWork = true;
      this.workUpdateMode = false;
      this.workData = {
        pmMstId: this.popupParam.pmMstId,  // 정비오더 일련번호
        pmMstWorkId: uid(),  // 작업계획 일련번호
        pmMstWorkName: '',  // 작업내용
        sortOrder: (this.gridWork.data.length + 1) * 10,  // 순번
        workOprTypeCd: null,  // 작업제어구분
        workpermitTypes: '',  // 작업허가서 종류
        sopWorkPermitId: '',  // 작업허가서 일련번호
        workRiskFactors: '',  // 위험요인
        vendorCd: '',  // 작업업체
        vendorWorkerCnt: '',  // 작업업체 작업인원
        workHolidayFlag: 'N',
        materialList: [],
        deleteMaterialList: [],
        serviceList: [],
        deleteServiceList: [],
        toolList: [],
        deleteToolList: [],
        workerList: [],
        deleteWorkerList: [],
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        chgUserId: this.$store.getters.user.userId,  // 수정자 ID
      }
    },
    removeRowWork() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '작업계획을 삭제하시겠습니까?',
        // TODO : 작업계획의 모든 정보가 삭제됩니다.\r\n작업계획을 삭제하시겠습니까?
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.removeworkUrl, this.workData.pmMstWorkId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('ALERT', {
              title: '안내 ', /* 안내 */
              message: '작업계획이 삭제되었습니다.', /* 작업계획취소가 처리되었습니다. */
              type: 'success', // success / info / warning / error
            });
            this.getWorks();
          });
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveWork() {
      if (this.selectedWork && this.workUpdateMode) {
        this.saveWorkUrl = transactionConfig.pm.mst.work.update.url;
        this.mappingType2 = 'PUT';
      } else {
        this.saveWorkUrl = transactionConfig.pm.mst.work.insert.url;
        this.mappingType2 = 'POST';
      }
      this.$refs['editForm2'].validate().then(_result => {
        if (this.$comm.validTable(this.grid.columns, this.workData.materialList)) {
          if (this.$comm.validTable(this.grid2.columns, this.workData.serviceList)) {
            if (_result) {
              window.getApp.$emit('CONFIRM', {
                title: '확인',
                message: '저장하시겠습니까?',
                // TODO : 필요시 추가하세요.
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.workData.regUserId = this.$store.getters.user.userId;
                  this.workData.chgUserId = this.$store.getters.user.userId;
                  this.isSave2 = !this.isSave2;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('APP_VALID_ERROR');
            }
          }
        }
      });
    },
    saveCallbackWork(_result) {
      this.workData.pmMstWorkId = _result.data;
      this.$http.url = this.worklistUrl;
      this.$http.param = {pmMstId: this.popupParam.pmMstId};
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.gridWork.data = _result.data;
        this.rowClick(this.workData);
      },);
      window.getApp.$emit('APP_REQUEST_SUCCESS');
    },
    addRowCost() {
      this.popupOptions.title = '코스트센터 추가'; // 작업자 추가
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.pmData.plantCd,
        maintenanceFlag: '',
        purchaseFlag: '',
      };
      this.popupOptions.target = () => import(`${'@/pages/common/cc/costCenterPop.vue'}`);
      this.popupOptions.isFull = false;
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeaddRowCost;
    },
    closeaddRowCost(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.pmData.costList, { costCenterCd: item.costCenterCd }) === -1) {
            this.pmData.costList.push({
              pmMstId: this.popupParam.pmMstId,  // 작업계획 일련번호
              costCenterCd: item.costCenterCd,  // 코스트센터 코드
              costCenterNo: item.costCenterNo,  // 코스트센터 코드
              costCenterName: item.costCenterName,  // 코스트센터 코드
              calculatePercent: (this.pmData.costList.length == 0 && data.length == 1 ? '100' : ''),  // 정산율
              calculateCost: '',  // 정산금액
              remark: '',  // 비고
              editFlag: 'C',
            })
          }
        });
      }
    },
    removeRowCost() {
      let selectData = this.$refs['gridCost'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.removecostUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.pmData.costList = this.$_.reject(this.pmData.costList, item);
              })
              this.$refs['gridCost'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    changeWorkOprType() {
      if (this.workData.workOprTypeCd === 'WOTC000001') { // 자체작업
        this.workData.vendorCd = '';
        this.workData.vendorWorkerCnt = null;
      } else {
        this.workData.workDeptCd = '';
        this.workData.workUsers = [];
      }
    },
    addRowBom() {
      this.popupOptions.title = '설비별 BOM 추가'; // 작업자재 추가
      this.popupOptions.param = {
        type: 'multiple',
        equipmentCds: this.equips,
      };
      this.popupOptions.target = () => import(`${'@/pages/common/material/bomPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeaddRowBom;
    },
    closeaddRowBom(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.workData.materialList, { materialCd: item.equipmentBomConnCd }) === -1) {
            this.workData.materialList.push({
              pmMstId: this.popupParam.pmMstId,  // 작업지시번호
              pmMstWorkId: this.workData.pmMstWorkId,
              materialCd: item.equipmentBomConnCd,  // 작업자재 일련번호
              materialNo: item.materialNo,  // 작업자재 일련번호
              materialName: item.equipmentBomName,  // 작업자재
              materialDesc: '',  // 작업자재 용도
              unitPrice: item.price,  // 단가
              unitEa: '',  // 수량
              priceCost: '',  // 소계
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              editFlag: 'C',
            })
          }
        });
      }
    },
    addRowService() {
      this.popupOptions.title = '작업업체 추가'; // 작업자 추가
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/vendor/vendorPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeaddRowService;
    },
    closeaddRowService(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.workData.serviceList, { vendorCd: item.vendorCd }) === -1) {
            this.workData.serviceList.push({
              pmMstId: this.popupParam.pmMstId,  // 작업지시번호
              pmMstWorkId: this.popupParam.pmMstWorkId,
              vendorCd: item.vendorCd,
              vendorName: item.vendorName,
              workDate: '',
              unitEa: '',
              priceCost: '',
              costFactorCd: null,
              manCost: '',
              materialCost: '',
              sumCost: '',
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              editFlag: 'C',
            })
          }
        });
      }
    },
    removeRowService() {
      let selectData = this.$refs['grid2'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.workData.deleteServiceList) this.workData.deleteServiceList = [];
        this.$_.forEach(selectData, item => {
          if (item.editFlag !== 'C'
            && this.$_.findIndex(this.workData.deleteServiceList, { vendorCd: item.vendorCd }) === -1) {
            this.workData.deleteServiceList.push(item)
          }
          this.workData.serviceList = this.$_.reject(this.workData.serviceList, item);
        })
        this.$refs['grid2'].$refs['compo-table'].clearSelection();
      }
    },
    addRowTool() {
      this.popupOptions.title = '공기구 추가'; // 작업자 추가
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.pmData.plantCd
      };
      this.popupOptions.target = () => import(`${'@/pages/common/tl/toolPop.vue'}`);
      this.popupOptions.isFull = false;
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeRowTool;
    },
    closeRowTool(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.workData.toolList, { toolId: item.toolId }) === -1) {
            this.workData.toolList.push({
              pmMstId: this.popupParam.pmMstId,
              pmMstWorkId: this.workData.pmMstWorkId,
              toolId: item.toolId,
              toolName: item.toolName,
              toolStandard: item.toolStandard,
              toolCnt: 0,
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              editFlag: 'C',
            })
          }
        });
      }
    },
    removeRowTool() {
      let selectData = this.$refs['toolGrid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.workData.deleteToolList) this.workData.deleteToolList = [];
        this.$_.forEach(selectData, item => {
          if (item.editFlag !== 'C'
            && this.$_.findIndex(this.workData.deleteToolList, { materialCd: item.materialCd }) === -1) {
            this.workData.deleteToolList.push(item)
          }
          this.workData.toolList = this.$_.reject(this.workData.toolList, item);
        })
        this.$refs['toolGrid'].$refs['compo-table'].clearSelection();
      }
    },
    addRowWorker() {
      this.popupOptions.title = '작업자 추가'; // 작업자 추가
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.pmData.plantCd
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.isFull = false;
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeRowWorker;
    },
    closeRowWorker(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          this.workData.workerList.push({
            pmMstId: this.popupParam.pmMstId,
            pmMstWorkId: this.workData.pmMstWorkId,
            pmMstWorkerId: uid(),
            skillName: item.skillName,
            workerName: item.userName,
            regUserId: this.$store.getters.user.userId,  // 등록자 ID
            editFlag: 'C',
          })
        });
      }
    },
    addRowWorker2() {
      this.workData.workerList.push({
        pmMstId: this.popupParam.pmMstId,
        pmMstWorkId: this.workData.pmMstWorkId,
        pmMstWorkerId: uid(),
        skillName: '',
        workerName: '',
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        editFlag: 'C',
      })
    },
    removeRowWorker() {
      let selectData = this.$refs['workerGrid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.workData.deleteWorkerList) this.workData.deleteWorkerList = [];
        this.$_.forEach(selectData, item => {
          if (item.editFlag !== 'C'
            && this.$_.findIndex(this.workData.deleteWorkerList, { pmMstWorkerId: item.pmMstWorkerId }) === -1) {
            this.workData.deleteWorkerList.push(item)
          }
          this.workData.workerList = this.$_.reject(this.workData.workerList, item);
        })
        this.$refs['workerGrid'].$refs['compo-table'].clearSelection();
      }
    },
    tableDataChangeMaterial(props) {
      if (props.row['unitPrice'] > 0 && props.row['unitEa'] > 0) {
        this.$set(props.row, 'priceCost', parseInt(props.row['unitPrice']) * parseInt(props.row['unitEa']));
      } else {
        this.$set(props.row, 'priceCost', 0);
      }
    },
    tableDataChangeService(props, col) {
      if (col.name !== 'manCost' && col.name !== 'materialCost') {
        if (props.row['priceCost'] > 0 && props.row['manCnt'] > 0) {
          this.$set(props.row, 'manCost', parseInt(props.row['priceCost']) * parseInt(props.row['manCnt']));
          this.$set(props.row, 'sumCost', parseInt(props.row['manCost']) + parseInt(props.row['materialCost'] || 0));
        } else {
          this.$set(props.row, 'manCost', 0);
          this.$set(props.row, 'sumCost', parseInt(props.row['manCost']) + parseInt(props.row['materialCost'] || 0));
        }
      } else {
        this.$set(props.row, 'sumCost', parseInt(props.row['manCost']) + parseInt(props.row['materialCost']));
      }
    },
    datachange(row) {
      if (row['editFlag'] !== 'C') {
        this.$set(row, 'editFlag', 'U')
      }
    },
    setNextDt() {
      if (this.pmData.pmTypeCd === 'TBM') {
        if (!this.pmData.pmFirstDt || !this.pmData.pmCycleUnitCd || !this.pmData.pmCycleTerm) return;
        let newDate = new Date(this.pmData.pmFirstDt);
        if (this.pmData.pmRecentDt) {
          newDate = new Date(this.pmData.pmRecentDt);
        }
        if (this.pmData.pmCycleUnitCd === 'Y') {
          newDate.setFullYear(newDate.getFullYear() + this.pmData.pmCycleTerm);
          newDate.setMonth(newDate.getMonth() + 1);
        } else if (this.pmData.pmCycleUnitCd === 'M') {
          newDate.setMonth(newDate.getMonth() + this.pmData.pmCycleTerm + 1);
        } else if (this.pmData.pmCycleUnitCd === 'W') {
          newDate.setDate(newDate.getDate() + (this.pmData.pmCycleTerm * 7));
          newDate.setMonth(newDate.getMonth() + 1);
        } else if (this.pmData.pmCycleUnitCd === 'D') {
          newDate.setDate(newDate.getDate() + this.pmData.pmCycleTerm);
          newDate.setMonth(newDate.getMonth() + 1);
        }
        let month = ''
        let day = ''
        if (newDate.getMonth() < 10) {
          month = '0' + newDate.getMonth()
        } else {
          month =  newDate.getMonth()
        }
        if (newDate.getDate() < 10) {
          day = '0' + newDate.getDate()
        } else {
          day = newDate.getDate()
        }
        this.pmData.pmNextDt = newDate.getFullYear() + '-' + month +'-' + day;

        let newDate2 = new Date(this.pmData.pmNextDt);
        if (this.pmData.pmBaseDate != 0) {
          newDate2.setDate(newDate2.getDate() - this.pmData.pmBaseDate);
          newDate2.setMonth(newDate2.getMonth() + 1);
        } else {
          newDate2.setMonth(newDate2.getMonth() + 1);
        }
        let month2 = ''
        let day2 = ''
        if (newDate2.getMonth() < 10) {
          month2 = '0' + newDate2.getMonth()
        } else {
          month2 =  newDate2.getMonth()
        }
        if (newDate2.getDate() < 10) {
          day2 = '0' + newDate2.getDate()
        } else {
          day2 = newDate2.getDate()
        }
        this.pmData.pmOrderDate = newDate2.getFullYear() + '-' + month2 +'-' + day2;
      } else if (this.pmData.pmTypeCd === 'UBM') {
        this.pmData.operationSumTime = 0;
        if (!this.pmData.pmFirstDt || !this.pmData.operationTime) return;
        let newDate = this.pmData.pmFirstDt
        if (this.pmData.pmRecentDt) {
          newDate = this.pmData.pmRecentDt;
        }
        if (this.pmData.pmRecentDt <= this.pmData.pmFirstDt) {
          newDate = this.pmData.pmFirstDt;

        }

        // let copyEquipList = this.$_.filter(this.equipList, item => {
        //     return item.operationStartDate >= this.pmData.pmFirstDt
        // });
        // console.log(copyEquipList)
        this.$_.forEach(this.equipList, _item => {
          // pm 최초기준일 >= 설비의 최초가동일이고 pm 최초기준일이 다음 설비의 최초가동일보다 클때
          // 마지막일
          if (newDate <= _item.operationStartDate && 
            !(_item.endDate) &&
            newDate <= this.$comm.getToday()) {
            this.pmData.operationSumTime = Number(this.pmData.operationSumTime) + Number(_item.operationSumTime)
           
           // 사이
          } else if (newDate >= _item.operationStartDate &&
            newDate <= _item.endDate) {
            let start = this.$comm.moment(newDate)
            let end = this.$comm.moment(_item.endDate)
            let count = this.$comm.getDatediff(start, end);
            this.pmData.operationSumTime += (Number(_item.operateTime) * count) 

          // 최초기준일이 최초가동일보다 최근일일때
          } else if (newDate >= _item.operationStartDate && 
            !(_item.endDate) &&
            newDate <= this.$comm.getToday()) {
            let start = this.$comm.moment(newDate)
            let end = this.$comm.moment(this.$comm.getToday())
            let count = this.$comm.getDatediff(start, end) + 1;
            this.pmData.operationSumTime += (Number(_item.operateTime) * count) 
            

          } else if (newDate <= _item.operationStartDate && 
            newDate <= _item.endDate) {
            let start = this.$comm.moment(newDate)
            let end = this.$comm.moment(_item.endDate)
            let count = this.$comm.getDatediff(start, end);
            this.pmData.operationSumTime += (Number(_item.operateTime) * count) 
          }
        })
      }
    },
    setOrderDt() {
      if (!this.pmData.pmFirstDt || !this.pmData.pmCycleUnitCd || !this.pmData.pmCycleTerm)  return;
      let newDate = new Date(this.pmData.pmNextDt);
      if (this.pmData.pmBaseDate != 0) {
        newDate.setDate(newDate.getDate() - this.pmData.pmBaseDate);
        newDate.setMonth(newDate.getMonth() + 1);
      } else {
        newDate.setMonth(newDate.getMonth() + 1);
      }
      let month = ''
      let day = ''
      if (newDate.getMonth() < 10) {
        month = '0' + newDate.getMonth()
      } else {
        month =  newDate.getMonth()
      }
      if (newDate.getDate() < 10) {
        day = '0' + newDate.getDate()
      } else {
        day = newDate.getDate()
      }

      this.pmData.pmOrderDate = newDate.getFullYear() + '-' + month +'-' + day;
    },
    standardData() {
      this.popupOptions.title = '기존 정비요청 검색'; // 정비작업표준 검색
      this.popupOptions.param = {
        type: 'single',
        plantCd: this.pmData.plantCd
      };
      this.popupOptions.target = () => import(`${'@/pages/mdm/sws/workStandardPopUp.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeStandardPopup;
    },
    closeStandardPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data.length === 1) {
        this.$http.url = selectConfig.mdm.sws.mst.copy.url;
        this.$http.param = {mdmSwsId: data[0].mdmSwsId};
        this.$http.type = 'GET';
         this.$http.request((_result) => {
          let _save = {};

          this.$set(_save, 'woWorkTypeCd', _result.data.woWorkTypeCd)
          this.$set(_save, 'pmTypeCd', 'TBM')
          this.$set(_save, 'planUserId', this.$store.getters.user.userId)
          this.$set(_save, 'pmMstName', _result.data.swsName)
          this.$set(_save, 'plantCd', _result.data.plantCd)
          this.$set(_save, 'equipmentCd', _result.data.equipmentCd)
          this.$set(_save, 'funcLocationCd', _result.data.funcLocationCd)
          this.$set(_save, 'workDeptCd', _result.data.workDeptCd)
          this.$set(_save, 'workDepts', _result.data.workDepts)
          this.$set(_save, 'useFlag', 'Y')
          this.$set(_save, 'regUserId', this.$store.getters.user.userId)
          this.$set(_save, 'chgUserId', this.$store.getters.user.userId)
          this.$set(_save, 'remark', _result.data.remark)
          this.$set(_save, 'costList', _result.data.costList)
          this.$set(_save, 'workList', _result.data.workList)
          this.$set(_save, 'equipList', _result.data.equipList)

          this.$_.forEach(_save.workList, _item => {
            _item.pmMstWorkName = _item.mdmSwsWorkName
          })
          this.$http.url = this.copyUrl;
          this.$http.type = 'POST';
          this.$http.param = _save
          this.$http.request((_result) => {
            this.popupParam.pmMstId = _result.data.pmMstId
            this.getData();
            window.getApp.$emit('APP_REQUEST_SUCCESS');
          },);
        },);
      }
    },
  }
};
</script>